<template>
  <div>
    <page-header
      :title="'Manage Coupon'"
      :sub-heading="true"
      :sub-header="'Coupon '"
      :link-name="'Manage Coupon'"
      :link-url="'/coupon'"
    ></page-header>
    <div class="usp sub-page-container row">
      <div class="col-xs-12">
        <div class="usp__nav tab-nav">
          <!-- <router-link :to="{ name: 'edit-coupon' }" class="tab-nav__el" exact
            >Edit Coupon
          </router-link> -->
          <router-link :to="{ name: 'promo-codes' }" class="tab-nav__el" exact
            >Promo Codes
          </router-link>
          <router-link
            :to="{
              name: 'create-promo-code',
            }"
            class="tab-nav__el"
            exact
            >Create PromoCode
          </router-link>
        </div>
        <div class="usp__content tab-content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../../layouts/components/fullPage/PageHeader";
export default {
  name: "ManagePromoCodes",
  components: { PageHeader },
};
</script>

<style scoped></style>
